@import '../variables';


@mixin btn-xl() {
    font-size: 1.375rem;
    height: calc(3.625rem + 2px);
    padding: 1.125rem 3rem;
    line-height: 1;
    font-weight: $font-weight-medium;

    &.btn-svg-icon {
        width: calc(3.625rem + 2px);
    }
}
@mixin btn-lg() {
    font-size: 0.925rem;
    height: calc(1.95rem + 2px);
    padding: .5125rem 1rem;
    line-height: 1;
    border-radius: 6px;
    font-weight: $font-weight-medium;

    &.btn-svg-icon {
        width: calc(2.75rem + 2px);
    }
}
@mixin btn-nl() {
    font-size: 1rem;
    height: calc(2.25rem + 2px);
    line-height: 1.5;
    padding: .375rem 1.25rem;
    font-weight: $font-weight-medium;

    &.btn-svg-icon {
        width: calc(2.25rem + 2px);
    }
}
@mixin btn-sm() {
    font-size: .875rem;
    height: calc(1.875rem + 2px);
    line-height: 1.25;
    padding: .375rem 1rem;
    font-weight: $font-weight-medium;

    &.btn-svg-icon {
        width: calc(1.875rem + 2px);
    }
}
@mixin btn-xs() {
    font-size: .8125rem;
    height: calc(1.5rem + 2px);
    line-height: 1.25;
    padding: .25rem .5625rem;
    font-weight: $font-weight-medium;

    &.btn-svg-icon {
        width: calc(1.5rem + 2px);
    }
}