@import url(https://fonts.googleapis.com/css?family=Raleway:100);
 
.slider-redirect{
position:relative;
width:400px;
height:2px;
margin-top:-5px;
}
.line-redirect{
position:absolute;
background:#4a8df8;
width:400px;
height:2px;
}
.break-redirect{
position:absolute;
background:#ffffff;
width:6px;
height:2px;
}

.center-page-1 {
    text-align: -webkit-center;
    top: 50%;
    position: relative;
    transform: translateY(-50%);
}

.center-page {
    text-align: -webkit-center;
    margin-top: 40px;
    position: relative;
    transform: translateY(-50%);
}
 
.dot1-redirect{
-webkit-animation: loading 2s infinite;
-moz-animation: loading 2s infinite;
-ms-animation: loading 2s infinite;
-o-animation: loading 2s infinite;
animation: loading 2s infinite;
}
.dot2-redirect{
-webkit-animation: loading 2s 0.5s infinite;
-moz-animation: loading 2s 0.5s infinite;
-ms-animation: loading 2s 0.5s infinite;
-o-animation: loading 2s 0.5s infinite;
animation: loading 2s 0.5s infinite;
}
.dot3-redirect{
-webkit-animation: loading 2s 1s infinite;
-moz-animation: loading 2s 1s infinite;
-ms-animation: loading 2s 1s infinite;
-o-animation: loading 2s 1s infinite;
animation: loading 2s 1s infinite;
}

@keyframes loading {
from { left: 0; }
to { left: 400px; }
}
@-moz-keyframes loading {
from { left: 0; }
to { left: 400px; }
}
@-webkit-keyframes loading {
from { left: 0; }
to { left: 400px; }
}
@-ms-keyframes loading {
from { left: 0; }
to { left: 400px; }
}
@-o-keyframes loading {
from { left: 0; }
to { left: 400px; }
}

.showOnlyMobile {
    display: none  !important;
}

.showOnlyDesktop {
    display: none  !important;
}

@media all and (max-width: 600px)
{
    .showOnlyMobile {
        display: block !important;
    }
}

@media all and (min-width: 600px)
{
    .showOnlyDesktop {
        display: block  !important;
    }
}
    